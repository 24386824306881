import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaBlock from "../components/organisms/media-block";
import MediaCarousel from "../components/organisms/media-carousel";

function DesignGuideGroup({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Guide Group" />
      <h2>Guide Group</h2>
      <p className="design__text p2">
        Guide Groups can be used to display multiple guide steps or
        instructions.
      </p>
      <div className="design__stories">
        <DesignStory
          title="Guide Group"
          component={MediaCarousel}
          subComponent={MediaBlock}
          type="carousel"
          props={designProps}
        />
      </div>
    </div>
  );
}

DesignGuideGroup.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignGuideGroup.defaultProps = {
  props: {},
};

export default DesignGuideGroup;
